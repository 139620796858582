//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';
import VError from '@/components/UI/VError';

export default {
  name: 'VInput',
  components: {
    VIcon,
    VError,
  },

  model: {
    prop: 'value',
    event: 'changed',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    leftIconName: {
      type: String,
      default: '',
    },
    rightIconName: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    passwordControl: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    maxLength: Number,
    tabindex: Number,
  },
  data: () => ({
    isFocused: false,
    passwordVisible: false,
  }),
  computed: {
    checkingInputType() {
      return this.passwordVisible ? 'text' : 'password';
    },

    imageIconFromBackend() {
      return this.leftIconName.includes('https');
    },
  },

  created() {
    return this.passwordControl
      ? this.passwordVisible = false
      : this.passwordVisible = true
  },

  methods: {
    emitChange(event) {
      this.$emit('changed', event.target.value);
    },
    setFocus() {
      this.isFocused = true;
    },
    setBlur() {
      this.isFocused = false;
    },

    toggleShownPassword() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
}
