//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { PRELOADER_TYPES } from '@/utils/constants/UI';

export default {
  name: 'VPreloader',

  props: {
    name: {
      type: String,
      default: 'dots',
    },
    shownPreloader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    typeOfPreloader() {
      switch (this.name) {
        case PRELOADER_TYPES.DOTS:
          return PRELOADER_TYPES.DOTS;
        case PRELOADER_TYPES.SPINNER:
          return PRELOADER_TYPES.SPINNER
      }
    },
  },
}
