//
//
//
//
//
//
//
//
//
//
//
//

// общие иконки
import BellIcon from 'assets/icons/bell-icon.svg?inline';
import LogoutIcon from 'assets/icons/logout-icon.svg?inline';
import WarningIcon from 'assets/icons/warning-icon.svg?inline';
import CloseIcon from 'assets/icons/close-icon.svg?inline';
import TrendingDownIcon from 'assets/icons/trending-down-icon.svg?inline';
import TrendingUpIcon from 'assets/icons/trending-up-icon.svg?inline';
import InfoIcon from 'assets/icons/info-icon.svg?inline';
import BalanceIcon from 'assets/icons/balance-icon.svg?inline';
import QuestionIcon from 'assets/icons/question-icon.svg?inline';
import SignedIcon from 'assets/icons/signed-icon.svg?inline';
import AtomIcon from 'assets/icons/atom-icon.svg?inline';
import CreditCardIcon from 'assets/icons/credit-card-icon.svg?inline';
import PaperClipIcon from 'assets/icons/paper-clip-icon.svg?inline';
import SaveIcon from 'assets/icons/save-icon.svg?inline';
import TrashIcon from 'assets/icons/trash-icon.svg?inline';
import DevelopmentIcon from 'assets/icons/development-icon.svg?inline';
import ExclamationIcon from 'assets/icons/exclamation-icon.svg?inline';
import TwoStepIcon from 'assets/icons/two-step-icon.svg?inline';
import NotificationSuccessIcon from 'assets/icons/notification-success-icon.svg?inline';
import CameraIcon from 'assets/icons/camera-icon.svg?inline';

// иконки ui
import LockIcon from 'assets/icons/ui/lock-icon.svg?inline';
import EditIcon from 'assets/icons/ui/edit-icon.svg?inline';
import ActivePenIcon from 'assets/icons/ui/active-pan-icon.svg?inline';
import ActiveIcon from 'assets/icons/ui/active-icon.svg?inline';
import AccordionArrow from 'assets/icons/ui/accordion-arrow.svg?inline';
import CopyIcon from 'assets/icons/ui/copy-icon.svg?inline';
import PersonIcon from 'assets/icons/ui/person-icon.svg?inline';
import MailIcon from 'assets/icons/ui/mail-icon.svg?inline';
import SelectArrowIcon from 'assets/icons/ui/select-arrow-icon.svg?inline';
import LocationIcon from 'assets/icons/ui/location-icon.svg?inline';
import ArrowIcon from 'assets/icons/ui/arrow-icon.svg?inline';
import QuestionAnswerIcon from 'assets/icons/ui/question-answer-icon.svg?inline';
import OutputBtnIcon from 'assets/icons/ui/output-icon.svg?inline';
import InputBtnIcon from 'assets/icons/ui/input-icon.svg?inline';
import DownloadIcon from 'assets/icons/ui/download-icon.svg?inline';
import LanguageIcon from 'assets/icons/ui/language-icon.svg?inline';
import CalendarIcon from 'assets/icons/ui/calendar-icon.svg?inline';
import VisibleOnIcon from 'assets/icons/ui/visibility-on.svg?inline';
import VisibleOffIcon from 'assets/icons/ui/visibility-off.svg?inline';
import PhoneIcon from 'assets/icons/ui/phone-icon.svg?inline';

// иконки навигации кабинета
import DashboardIcon from 'assets/icons/navigations/dashboard-icon.svg?inline';
import ServicesIcon from 'assets/icons/navigations/services-icon.svg?inline';
import ExchangeIcon from 'assets/icons/navigations/exchange-icon.svg?inline';
import ReferralIcon from 'assets/icons/navigations/referal-icon.svg?inline';

import WalletIcon from 'assets/icons/navigations/wallet-icon.svg?inline';

import NewsIcon from 'assets/icons/navigations/news-icon.svg?inline';
import BlogIcon from 'assets/icons/navigations/blog-icon.svg?inline';
import FaqIcon from 'assets/icons/navigations/faq-icon.svg?inline';
import DocumentsIcon from 'assets/icons/navigations/documents-icon.svg?inline';
import SupportIcon from 'assets/icons/navigations/support-icon.svg?inline';

import SecurityIcon from 'assets/icons/navigations/security-icon.svg?inline';
import SettingsIcon from 'assets/icons/navigations/settings-icon.svg?inline';

// иконки бургера
import CollapsedBurgerIcon from 'assets/icons/burger/collapsed-burger-icon.svg?inline';
import OpenedBurgerIcon from 'assets/icons/burger/opened-burger-icon.svg?inline';

// иконки кашелька
import SuccessIcon from 'assets/icons/wallet/done-icon.svg?inline';
import RejectIcon from 'assets/icons/wallet/reject-icon.svg?inline';
import TransferIcon from 'assets/icons/wallet/transfer-icon.svg?inline';
import WaitIcon from 'assets/icons/wallet/wait-icon.svg?inline';
import CurrencyIcon from 'assets/icons/wallet/currency-icon.svg?inline';
import PublicIcon from 'assets/icons/wallet/public-icon.svg?inline';
import AddressIcon from 'assets/icons/wallet/address-icon.svg?inline';

// иконки валюты
import DollarIcon from 'assets/icons/currency/dollar-icon.svg?inline';

// иконки верификации
import DriverLicenceImage from '@/assets/icons/verificationModal/driver-licence.svg?inline';
import PassportImage from '@/assets/icons/verificationModal/passport.svg?inline';

// иконки сервисов
import InsuranceIcon from 'assets/icons/services/inshurence.svg?inline';
import EarnIcon from 'assets/icons/services/earn.svg?inline';
import MineIcon from 'assets/icons/services/mine.svg?inline';

// иконки EARN
import BluePointIcon from 'assets/icons/services/earn/blue-point-icon.svg?inline';
import BlueControlIcon from 'assets/icons/services/earn/blue-control-icon.svg?inline';
import OrangeCubeIcon from 'assets/icons/services/earn/orange-cube-icon.svg?inline';

import BluePointIcon1 from 'assets/icons/services/blue-point-icon.svg?inline';
import BlueControlIcon1 from 'assets/icons/services/blue-control-icon.svg?inline';

// иконки уведомлений
import NotificationWarning from 'assets/icons/notifications/notification-warning.svg?inline';



export default {
  name: 'VIcon',
  components: {
    // общие иконки
    BellIcon,
    LogoutIcon,
    WarningIcon,
    CloseIcon,
    TrendingDownIcon,
    TrendingUpIcon,
    InfoIcon,
    BalanceIcon,
    QuestionIcon,
    SignedIcon,
    AtomIcon,
    CreditCardIcon,
    PaperClipIcon,
    SaveIcon,
    TrashIcon,
    DevelopmentIcon,
    ExclamationIcon,
    TwoStepIcon,
    NotificationSuccessIcon,

    // иконки ui
    LockIcon,
    EditIcon,
    ActivePenIcon,
    ActiveIcon,
    AccordionArrow,
    CopyIcon,
    PersonIcon,
    MailIcon,
    SelectArrowIcon,
    LocationIcon,
    ArrowIcon,
    QuestionAnswerIcon,
    OutputBtnIcon,
    InputBtnIcon,
    DownloadIcon,
    LanguageIcon,
    CalendarIcon,
    VisibleOnIcon,
    VisibleOffIcon,
    PhoneIcon,

    // иконки навигации кабинета
    DashboardIcon,
    ServicesIcon,
    ExchangeIcon,
    ReferralIcon,
    WalletIcon,
    NewsIcon,
    BlogIcon,
    FaqIcon,
    SupportIcon,
    DocumentsIcon,
    SecurityIcon,
    SettingsIcon,

    // иконки бургера
    CollapsedBurgerIcon,
    OpenedBurgerIcon,

    // иконки кашелька
    SuccessIcon,
    RejectIcon,
    TransferIcon,
    WaitIcon,
    CurrencyIcon,
    PublicIcon,
    AddressIcon,

    // иконки валюты
    DollarIcon,

    // иконки верификации
    PassportImage,
    DriverLicenceImage,

    // иконки сервисов
    InsuranceIcon,
    EarnIcon,
    MineIcon,

    // иконки EARN
    BluePointIcon,
    BlueControlIcon,
    OrangeCubeIcon,

    BluePointIcon1,
    BlueControlIcon1,

    // иконки уведомлений
    NotificationWarning,
    CameraIcon
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number,
      default: 16,
    },

    isClickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stylesForIcon() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
  },
}
