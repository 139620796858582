//
//
//
//
//
//

export default {
  name: 'VLabel',

  props: {
    inner: {
      type: [String, Number],
    },
  },
}
