//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VInput from '@/components/UI/VInput';
import VIcon from '@/components/UI/VIcon';
import VDropdown from '@/components/UI/Dropdown/VDropdown';

export default {
  name: 'VSelect',
  components: {
    VInput,
    VIcon,
    VDropdown,
  },

  model: {
    prop: 'value',
    event: 'selectOptionId',
  },
  props: {
    options: {
      type: Array,
      required: false,
    },
    value: {
      type: [Number, String],
    },
    forceValueLabel: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: null,
    },
    labelField: {
      type: String,
      default: 'name',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
    dropdownIsOpened: false,
  }),
  computed: {
    selectedValue() {
      if (this.dropdownIsOpened) {
        return this.search;
      }

      return this.getSelectedOptionLabel();
    },

    filteredOptions() {
      const optionsFilteredBySearchString = this.search.length
        ? this.options.filter(loopOption => loopOption[this.labelField].toLowerCase().includes(this.search.toLowerCase()))
        : this.options;

      return optionsFilteredBySearchString;
    },
  },

  methods: {
    closeDropdown() {
      this.search = '';
      this.dropdownIsOpened = false;
    },

    setFocus() {
      this.dropdownIsOpened = !this.dropdownIsOpened
      this.$emit('focus');
    },

    changeSearch(searchString) {
      this.search = searchString;
      this.$emit('searchValue', this.search);
    },

    selectOption(selectOption) {
      this.search = selectOption[this.labelField] || '';
      this.$emit('selectOption', selectOption);
      this.$emit('selectOptionId', selectOption?.id || null);

      this.closeDropdown();
    },

    getSelectedOption() {
      return this.options.find(option => option?.id === this.value) || null;
    },
    getSelectedOptionLabel() {
      if (this.value === null) {
        this.search = '';
      }

      // if (this.forceValueLabel) {
      //   return this.forceValueLabel;
      // }

      const selectedOption = this.getSelectedOption();
      // return (selectedOption && selectedOption[this.labelField]);
      return (selectedOption && selectedOption[this.labelField]) || '';
    },
  },
}
