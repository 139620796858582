//
//
//
//
//
//

export default {
  name: 'AuthorizationLayout',
  middleware: [
    'authorizationGuard',
  ],

  async created() {
    this.$i18n.setLocale('en');
  },

  mounted() {
    const script = document.createElement('script');
    script.src = "//code.tidio.co/8wavfewstv1zwgscwv4h80dpepfkn5n4.js";
    script.async = true;
    document.head.appendChild(script);
  }
}
