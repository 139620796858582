//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

import TheHeader from '@/components/System/Header/TheHeader';
import TheNavigation from '@/components/System/Navigation/TheNavigation';
import TheModals from '@/components/System/Modals/TheModals';
import TheNotifications from '@/components/System/Notifications/TheNotifications';

export default {
  name: 'CabinetLayout',
  middleware: [
    'cabinetGuard',
  ],

  components: {
    TheHeader,
    TheNavigation,
    TheModals,
    TheNotifications,
  },
  watch: {
    '$i18n.locale': {
      async handler() {
        await this.getCountries();
      },
    },
  },

  async created() {
    await Promise.all([
      this.init(),
      this.getCountries(),
    ])
  },

  methods: {
    ...mapActions('general', [
      'init',
    ]),
    ...mapActions('system/locations', [
      'getCountries',
    ]),
  },
  mounted() {
    const script = document.createElement('script');
    script.src = "//code.tidio.co/8wavfewstv1zwgscwv4h80dpepfkn5n4.js";
    script.async = true;
    document.head.appendChild(script);
  }
}
