//
//
//
//
//
//

export default {
  name: 'VTooltip',

  props: {
    tooltip: {
      type: String,
      default: '',
    },
  },
}
