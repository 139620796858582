//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'VError',
  components: {
    VIcon,
  },

  props: {
    error: {
      type: String,
      default: '',
    },
  },
}
