//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'VCheckbox',
  components: {
    VIcon,
  },

  model: {
    prop: 'value',
    event: 'checked',
  },

  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'checkbox',
    },
    id: {
      type: String,
      required: true,
    },
  },

  methods: {
    toggleCheckbox() {
      this.$emit('checked', !this.isChecked);
    },
  },
}
