var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.setBlur),expression:"setBlur"}],class:[
    'v-input',
    { 'v-input_has-value': _vm.value },
    { 'v-input_has-icon_left': Boolean(_vm.leftIconName) },
    { 'v-input_has-icon_right': Boolean(_vm.rightIconName) },
    { 'v-input_has-error': !_vm.isValid && !_vm.isFocused },
    { 'v-input_is-focused': _vm.isFocused },
    { 'v-input_is-disabled': _vm.isDisabled } ]},[(_vm.leftIconName && !_vm.imageIconFromBackend)?_c('VIcon',{class:[
      'v-input__icon',
      { 'v-input__icon_left': _vm.leftIconName }
    ],attrs:{"name":_vm.leftIconName,"height":20,"width":20}}):_vm._e(),_vm._v(" "),(_vm.imageIconFromBackend)?_c('img',{class:[
      'v-input__icon',
      { 'v-input__icon__image-left': _vm.leftIconName }
    ],attrs:{"src":_vm.leftIconName,"alt":null}}):_vm._e(),_vm._v(" "),(_vm.rightIconName)?[(!_vm.isFocused)?_c('VIcon',{class:[
      'v-input__icon',
      { 'v-input__icon_right': _vm.rightIconName }
    ],attrs:{"name":_vm.rightIconName,"height":18,"width":18}}):_c('VIcon',{class:[
      'v-input__icon',
      { 'v-input__icon_right': _vm.rightIconName }
    ],attrs:{"name":"ActivePenIcon","height":15,"width":15}})]:_vm._e(),_vm._v(" "),(_vm.passwordControl)?[(_vm.passwordVisible)?_c('VIcon',{staticClass:"v-input__icon-password",attrs:{"name":"VisibleOnIcon","height":20,"width":20},on:{"click":_vm.toggleShownPassword}}):_c('VIcon',{staticClass:"v-input__icon-password",attrs:{"name":"VisibleOffIcon","height":20,"width":20},on:{"click":_vm.toggleShownPassword}})]:_vm._e(),_vm._v(" "),(_vm.label)?_c('label',{class:[
      'v-input__label',
      { 'v-input__label-error': !_vm.isValid && !_vm.isFocused }
    ]},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('input',_vm._g(_vm._b({staticClass:"v-input__input",attrs:{"name":_vm.name,"type":_vm.checkingInputType,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder,"tabindex":_vm.tabindex},domProps:{"value":_vm.value},on:{"focus":_vm.setFocus,"input":_vm.emitChange,"change":_vm.emitChange}},'input',_vm.$attrs,false),_vm.$listeners)),_vm._v(" "),_c('transition',{attrs:{"name":"fadeIn"}},[(!_vm.isValid)?_c('VError',{staticClass:"v-input__input-error",attrs:{"error":_vm.error}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }