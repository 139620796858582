export default async function ({ store, redirect, route, $cookies }) {
  const fromValue = route.query.from ? `?from=${route.query.from}` : '';
  const currentPath = route.path;

  if (!$cookies.get('accessToken')) {
    $cookies.remove('i18n_redirected');
    
    if (currentPath.startsWith('/cabinet/referral')) {
      const registrationPath = `/authorization/registration${fromValue}`;
      return redirect(registrationPath);
    }

    const signInPath = `/authorization/sign-in${fromValue}`;
    
    if (currentPath !== signInPath) {
      return redirect(signInPath);
    }
  }

  if (store.state.cabinet.profile.user) {
    return true;
  }

  try {
    const userIsFetchedSuccessfully = await store.dispatch('cabinet/profile/fetchAndSetUser', undefined, { root: true });

    if (!userIsFetchedSuccessfully) {
      $cookies.remove('accessToken');
      $cookies.remove('i18n_redirected');
      return redirect(`/authorization/sign-in${fromValue}`);
    }
  } catch (e) {
    console.error(e);
    return redirect(`/authorization/sign-in${fromValue}`);
  }
}
