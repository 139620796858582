//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

import { V_BUTTON } from '@/utils/constants/UI';

export default {
  name: 'VButton',
  components: {
    VIcon,
  },

  props: {
    customType: {
      type: String,
      default: '',
      validator(val) {
        return Object.values(V_BUTTON)
          .indexOf(val) !== -1;
      },
    },
    to: {
      type: [String, Object],
      default: null,
    },
    tag: {
      type: String,
      default: 'button',
      validator(val) {
        return [
          'button',
          'nuxt-link',
          'a',
        ].indexOf(val) !== -1;
      },
    },
    type: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
  },
}
